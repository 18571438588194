@import "primereact/resources/themes/lara-light-blue/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

body,
html,
#root {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 14px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

//scroll style

::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: #f4f4f5;
}

::-webkit-scrollbar-thumb {
  background: #ced4da;
}


.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
  border: 2px solid #ced4da;
  box-shadow: none !important;
  border-color: none !important;

  &:hover {
    border: 2px solid #2D788B;
    box-shadow: none;
  }
  &:focus {
    border: 2px solid #2D788B;
    box-shadow: none;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #2D788B !important;
  border: 2px solid #2D788B !important;
  border-color: #ced4da !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
  border: 2px solid #ced4da;
  box-shadow: none;
  border-color: #2D788B !important;
  color: #ffffff;
  &:hover {
    border-color: #2D788B;
    background: #2D788B;
    color: #ffffff;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border: 2px solid #ced4da !important;
  border-color: #2D788B !important;
}

// //slider
// .slider {
//   -webkit-appearance: none;
//   width: 100%;
//   height: 6px;
//   border-radius: 10px;
//   background: #ffffff;
//   outline: none;
//   opacity: 0.7;
//   -webkit-transition: .2s;
//   transition: opacity .2s;
//   box-shadow: 0px 3px 6px #0000004D;
// }


// .slider::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   appearance: none;
//   width: 15px;
//   height: 15px;
//   border-radius: 100px;
//   background: #ffffff;
//   border: 2px solid #2D788B;
//   padding-top: 2px;
//   cursor: pointer;
// }

// .slider::-webkit-slider-runnable-track {
//   background: linear-gradient(#B281B7FC,#B281B7FC) 0/var(--sx) 100% no-repeat, #efefef !important;
// }
