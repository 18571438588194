.Route {
    position: absolute;
    right: 74px;
    top: 67px;
    width: 290px;
    box-shadow: 0px 0px 10px #00391882;
    border-radius: 5px;
    background-color: #DDECF0;
    padding: 10px;
  }
  