.p-button {
  // width: 42px !important;
  // height: 42px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  background-color: #2d788b;
  box-shadow: 0px 3px 6px #00391882 !important;
  border-radius: 5px;
  border-color: transparent !important;
  border: none !important;

  &:hover {
    border-color: transparent !important;
    background: #54818d !important;
    border: none !important;
    box-shadow: 0px 3px 6px #00391882 !important;
  }
}

.button-active {
  outline: none !important;
  border-color: transparent !important;
  background: #ddecf0 !important;
  border: none !important;
  box-shadow: 0px 3px 6px #00391882 !important;
  &:hover {
    border-color: transparent !important;
    background: #aac8d0 !important;
    border: none !important;
    box-shadow: 0px 3px 6px #00391882 !important;
  }
}
