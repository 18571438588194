.basemap-button {
  margin-right: 6px;
  display: flex;
  img {
    width: 55px;
    height: 55px;
    border: 2px solid #2d788b;
    border-radius: 6px;
  }
  &:hover {
    cursor: pointer;
  }
}
.basemap-group {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 337px;
  // width: 190px;
  // padding: 6px;
  border-radius: 6px;
  height: 55px;
  .basemap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 60px;
    margin: 0px 5px;
    width: 47px;
    .image-box {
      border-radius: 5px;
      width: 48px;
      height: 48px;
      box-shadow: 1px 1px 5px black;

      &:hover {
        cursor: pointer;
      }
    }
    .title {
      font-size: 10px;
      padding: 0px 8px;
      margin: 2px;
      text-align: center;
      line-height: 15px;
    }
  }
}

.Basemap {
  padding: 14px;
  display: flex;
  align-items: flex-end;
  user-select: none;
}
.Basemap-left {
  padding: 14px;
  display: flex;
  align-items: flex-end;
}
