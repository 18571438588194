.Toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 14px;
  .left-content {
    display: flex;
    align-items: center;
    width: 50%;
    input {
      width: 365px;
      height: 40px;
      border: none;
      outline: none;
      background-color: #ddecf0;
      box-shadow: 0px 3px 6px #0039184d;
      color: #2d788b;
      margin-right: 3px;

      &:focus {
        border: none;
        outline: none;
        background-color: #ddecf0;
        box-shadow: 0px 3px 6px #0039184d;
        color: #2d788b;
      }
    }

    .p-dropdown {
      background-color: #2d788b;
      box-shadow: 0px 3px 6px #00391882;
      height: 40px;
      border: none;
      margin: 6px;
      display: flex;
      span {
        color: white !important;
      }
    }
  }
  .right-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    .p-button {
      width: 42px !important;
      height: 42px !important;
    }
    
  }
}

.p-dropdown-panel {
  margin-top: 8px !important;
  background-color: #ddecf0 !important;
  box-shadow: 0px 0px 10px #00391882 !important;
  li {
    color: #2d788b !important;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #324e6b33;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #324e6b33;
}

