.Distance {
  position: absolute;
  right: 128px;
  top: 67px;
  width: 290px;
  box-shadow: 0px 0px 10px #00391882;
  border-radius: 5px;
  background-color: #DDECF0;
  padding: 10px;
  .select-point{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00391826;
    padding: 5px 12px;
    border-radius: 6px;
    .text{
      font-size: 13px;
    }
    
  }
  .slider-group{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
    .slider{
      display: flex;
      width: 60%;
    }
    .text{
      padding: 0 !important;
    }
  }
}

