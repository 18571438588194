.filter-data {
  position: absolute;
  top: 65px;
  right: 20px;
  user-select: none;
  .container {
    box-shadow: 0px 0px 10px  #00391882;
    background: rgb(221, 236, 240, 0.8);
    border-radius: 5px;
    width: 300px;
    .main-content {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      border-bottom: 1px solid #2d788b;
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #2d788b;
        padding: 4px 7px;
        border-radius: 8px;
        margin: 5px 3px;
        height: 30px;
        &:hover {
          background: #aac8d0;
          cursor: pointer;
        }
        img {
          margin-right: 4px;
          width: 22px;
        }
      }
    }
    .tag-selected {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #2d788b;
      padding: 4px 7px;
      border-radius: 8px;
      margin: 5px 3px;
      height: 30px;      
      background-color: #2D788B;
      &:hover {
        background: #438da0;
        cursor: pointer;
      }
      img {
        margin-right: 4px;
        width: 22px;
      }
      .text{
        color: white;
      }
     
    }
    .bottom-content {
      padding: 6px 7px 6px 15px;
      display: flex;
      justify-content: space-between;
      .group {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
        .text {
          font-size: 11px;
          white-space: nowrap;
        }
      }
      .p-button {
        width: 80px !important;
        height: 28px !important;
      }
    }
  }
}

.disabled{
  background-color: #e3e3e3;
  color: #9f9f9f;
  border: 1px solid #ababab  !important;
  user-select: none;
  &:hover{
    background-color: #e3e3e3 !important;
    cursor: not-allowed !important;
  }
}